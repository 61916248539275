<template>
  <div>
    <v-fade-transition mode="out-in">
      <v-row v-if="show">
        <v-col cols="12">
          <v-btn
            to="/classroom"
            rounded
            dark
            :class="`main-${subject.color}-bg`"
          >
            <v-icon>mdi-chevron-left</v-icon>Classroom
          </v-btn>
        </v-col>
        <v-col
          lg="6"
          xl="6"
          md="6"
          sm="6"
          cols="12"
          v-for="(action, index) in actions"
          :key="index"
        >
          <v-card
            elevation="1"
            :class="` secondary-${subject.color}-bg pa-3`"
            outlined
            rounded="lg"
            max-height="230"
          >
            <div class="d-flex justify-space-between">
              <v-btn
                :to="{ name: action.to }"
                rounded
                :class="`main-${subject.color}-bg white--text`"
                >View All</v-btn
              >
              <v-icon :class="`main-${subject.color}-text`" size="100">{{
                action.icon
              }}</v-icon>
            </div>
            <div class="mt-n3">
              <v-avatar :class="` main-${subject.color}-bg `" size="48">
                <h2 class="white--text">
                  {{ action.count ? action.count : 0 }}
                </h2>
              </v-avatar>
              <h2 :class="`main-${subject.color}-text`">
                {{ action.title }}
              </h2>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-fade-transition mode="out-in" v-if="!show">
      <Breadcrumbs transition="fade" baseRoute="/classroom" />
    </v-fade-transition>

    <v-fade-transition mode="out-in" v-if="!show">
      <router-view></router-view>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: "SubjectPage",
  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
  },
  computed: {
    subject() {
      return this.$store.state.subjectsModule.subject;
    },
    studentTaskCount() {
      return this.$store.state.subjectsModule.studentTaskCount;
    },
    show() {
      return this.$route.path.split("/").length == 3;
    },
    actions() {
      return [
        {
          icon: "mdi-book-open-page-variant-outline",
          to: "LessonAssigned",
          title: "Assigned Lessons",
          count: this.studentTaskCount?.lessonsCount,
        },
        {
          icon: "mdi-book-check-outline",
          to: "AssessmentAssigned",
          title: "Assigned Assessments",
          count: this.studentTaskCount?.assessmentsCount,
        },
      ];
    },
  },

  data: () => {
    return {};
  },
  async mounted() {
    if (!this.subject) {
      this.$router.push("/");
    } else {
      await this.$store.dispatch(
        "subjectsModule/getStudentTaskCount",
        this.subject.id
      );
    }
  },
};
</script>

<style>
.subjectCard {
  background: var(--main-red);
  height: 130px;
}
</style>
